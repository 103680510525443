<template>
  <LayoutParametrage>
    <template #current_page>
      <span
        >Template Bilan financier :
        <span class="font-semibold">{{ template.name }}</span>
      </span>
    </template>
    <template #add_button>
      <router-link
        class="btn-green px-6 py-2 sm:w-full md:mt-8 mr-4"
        to="/parametrage/bilan-financier/list"
      >
        Retour à la liste
      </router-link>
    </template>
    <template #modal>
      <div class="flex flex-col m-4">
        <TopicSheet
          v-for="(topic, idx) in template.topics"
          :key="idx"
          :topic="topic"
          :isReadOnly="true"
          :ref="'sheet' + topic.label"
        />
      </div>
    </template>
  </LayoutParametrage>
</template>

<script>
import TopicSheet from '../../../../components/BilanFinancier/TopicSheet.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    TopicSheet,
  },
  mounted() {
    if (!this.template.id) {
      this.$store.dispatch(
        'bilanFinancier/getTemplateById',
        this.$route.params.id,
      )
    }
  },
  computed: {
    ...mapGetters({
      template: 'bilanFinancier/getSelectedTemplate',
    }),
  },
  methods: {},
}
</script>
